/* eslint-disable no-undef */
import React, { useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import classes from './DrawerMenu.module.scss';
import { disconnect } from '../../api/Connect/Connect';
import { setMessages, setQuickResponses, setMessagesLoaded, setMode } from '../../store/actions/messages';
import { setResetChat, setDrawerIsOpen } from '../../store/actions/general';
import { resetGenesysSession, disconnectGenesysSession } from '../../store/actions/genesys';
import PopOver from '../controls/PopOver/PopOver';
import MuiPlaceholder from '../controls/MuiPlaceholder/MuiPlaceholder';


const DrawerMenu = () => {
  const { drawerIsOpen, muiFontLoaded } = useSelector(state => state.general);
  const { chatId, alias, secureKey, userId, chatEnded } = useSelector(state => state.genesys);
  const isMounted = useRef(true);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    }
  }, [])

  const handleOpen = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setDrawerIsOpen(drawerIsOpen ? false : true));
  }, [dispatch, drawerIsOpen]);

  const handleClose = useCallback((e) => {
    if (isMounted.current) {
      dispatch(setDrawerIsOpen(false));
    }
  }, [dispatch]);

  const restartChat = useCallback(() => {
    window.sutherland_variables.restart_chat = true;
    dispatch(setDrawerIsOpen(false))
    dispatch(setMessages([]));
    dispatch(setMode('bot'));
    dispatch(setQuickResponses([]));
    dispatch(setResetChat(false));
    dispatch(setMessagesLoaded(false));
    dispatch(disconnectGenesysSession());
    disconnect({
        chatId, 
        alias, 
        secureKey,
        userId,
        chatEnded
    }).then(() => {
      setTimeout(() => {
        dispatch(resetGenesysSession());
      }, 1500)
    });

  }, [dispatch, chatId, alias, secureKey, userId, chatEnded]);

    return (
      <div className={classes.DrawerMenu}>
        <button translate="no" className={classes.IconButton} onClick={handleOpen}>
          <MuiPlaceholder
            isMuiFontLoaded={muiFontLoaded}
            element={<div className={classes.MoreHoriz}>more_horiz</div>}
            width={30}
            height={30}
            backgroundColor={'#eeeeee'} />
        </button>
        <PopOver close={handleClose} opened={drawerIsOpen}>
          <ul className={classes.MenuBox}>
            <li className={classes.MenuItem} onClick={restartChat}>
              <div translate="no" className={classes.MenuItemIcon}>
                <MuiPlaceholder
                    isMuiFontLoaded={muiFontLoaded}
                    element={<span aria-hidden="true">restart_alt</span>}
                    width={0}
                    height={0}
                    backgroundColor={'transparent'} />
              </div>
              <div className="text">
                <Trans i18nKey="drawerMenu.restartChat">
                  Restart Chat
                </Trans>
              </div>
            </li>
          </ul>
        </PopOver>
      </div>
    );

};
export default DrawerMenu;
