import React from 'react';
import { Trans } from 'react-i18next';
import classes from './Header.module.scss';
import DrawerMenu from '../DrawerMenu/DrawerMenu';
import { useDispatch, useSelector } from 'react-redux';
import { setResetChat, setWidgetIsOpen, setDrawerIsOpen } from '../../store/actions/general';
import { resetGenesysSession } from '../../store/actions/genesys';
import { setMessages, setQuickResponses, setMode } from '../../store/actions/messages';
import MuiPlaceholder from '../controls/MuiPlaceholder/MuiPlaceholder';

const Header = ({socket}) => {
  const { muiFontLoaded } = useSelector(state => state.general);
  const { chatEnded } = useSelector(state => state.genesys);
  const dispatch = useDispatch();

  const minimizeWidget = () => {
    dispatch(setWidgetIsOpen(false));
    if (chatEnded) {
      dispatch(setWidgetIsOpen(false));
      dispatch(resetGenesysSession());
      dispatch(setMessages([]));
      dispatch(setMode('bot'));
      dispatch(setResetChat(false))
      dispatch(setQuickResponses([]));
    }
  };

  const closeWidget = () => {
    dispatch(setDrawerIsOpen(false));
    if (chatEnded || window.sutherland_variables.chat_ended) {
      dispatch(setWidgetIsOpen(false));
      dispatch(resetGenesysSession());
      dispatch(setMessages([]));
      dispatch(setMode('bot'));
      dispatch(setResetChat(false));
      dispatch(setQuickResponses([]));
    } else {
      dispatch(setResetChat(true));
    }
  };

  return (
    <div className={classes.Header} style={{background: window.sutherland_variables.ui.header_background_color}}>
      <div className={classes.HeaderTitle}>
        {window.sutherland_variables.ui.header_image_url ? (
          <div className={classes.HeaderTitleImage}>
            <img
              src={window.sutherland_variables.ui.header_image_url}
              alt='Logo'
              style={{
                width: window.sutherland_variables.ui.header_image_size,
              }}
            />
            <Trans i18nKey="header.title">
              {window.sutherland_variables.ui.header_title}
            </Trans>
          </div>
        ) : (
          <div className={classes.HeaderTitleText}>
            <Trans i18nKey="header.title">
              {window.sutherland_variables.ui.header_title}
            </Trans>
          </div>
        )}
        <div className={classes.HeaderButtons}>
          <DrawerMenu socket={socket}/>
          <button translate="no" className={classes.IconButton} onClick={minimizeWidget}>
            <MuiPlaceholder
              isMuiFontLoaded={muiFontLoaded}
              element={<div>remove</div>}
              width={30}
              height={30}
              backgroundColor={'#eeeeee'} />
          </button>
          <button translate="no" className={classes.IconButton} onClick={closeWidget}>
            <MuiPlaceholder
              isMuiFontLoaded={muiFontLoaded}
              element={<div>close</div>}
              width={30}
              height={30}
              backgroundColor={'#eeeeee'} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
