import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classes from './MessageComposer.module.scss';
import Text from '../controls/Text/Text';
import Message from '../../models/Message';
import { typing, send } from '../../api/Connect/Connect';
import { setApiError, refreshGenesysSession } from '../../store/actions/genesys';
import { setShowFeedback } from '../../store/actions/general';
import { addMessage, setQuickResponses, setIsTyping, setShowUpload } from '../../store/actions/messages';
import MuiPlaceholder from '../controls/MuiPlaceholder/MuiPlaceholder';

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

const MessageComposer = () => {
  const components = window.sutherland_variables.components;
  const { t } = useTranslation();
  const { chatId, alias, secureKey, userId, nextPosition, chatEnded } = useSelector(state => state.genesys);
  const { muiFontLoaded } = useSelector(state => state.general);
  const { mode } = useSelector(state => state.messages);
  const [newMessage, setNewMessage] = useState('');
  const debouncedInputValue = useDebounce(newMessage, 500);
  const [isTypingState, setisTypingState] = useState(false);
  const [limitReached, setLimitReached] = useState(false);
  const [composerRef, setComposerRef] = useState(null);
  const [multiLineStart, setMultiLineStart] = useState(false);
  // eslint-disable-next-line 
  const [fileuploadEnabled, setFileuploadEnabled] = useState(components["fileupload"]["enabled"])
  const dispatch = useDispatch()

  const sendMessage = (textareaRef = null) => {
    if (newMessage.trim() !== '') {

      if (newMessage === 'show_feedback') {
        dispatch(setShowFeedback(true));
      } else {
        const message = {
          "type": "text",
          "text": newMessage,
          "incoming": false,
        }
        dispatch(setApiError(false));
        dispatch(addMessage(new Message(message)));
        dispatch(setQuickResponses([]));
        dispatch(setIsTyping(true))
        if(textareaRef && textareaRef.current) { 
          textareaRef.current.style.height = 'inherit';
          setLimitReached(false);
        } else {
          composerRef.current.style.height = 'inherit';
          setLimitReached(false);
        }
        send({
          chatId,
          alias, 
          secureKey, 
          userId, 
          nextPosition,
          message: newMessage
        })
          .then((data) => {
            setNewMessage('');
            dispatch(refreshGenesysSession(data));
          })
          .catch((error) => {
            console.log('Genesys Error', error)
            dispatch(setApiError(true));
          })  
      }
    }
  };

  const showUploadModal = () => {
    dispatch(setShowUpload(true))
  }

  const handleTypingStart = (message, textareaRef = null) => {
    setNewMessage(message)
    if(textareaRef) { 
      textareaRef.current.style.height = 'inherit';
      setMultiLineStart(false);
      if(textareaRef.current.scrollHeight > 40) {
        setMultiLineStart(true);
      }
      if(message.length === 250) {
        setLimitReached(true);
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 20}px`;
      } else if (message.length === 0) {
        textareaRef.current.style.height = 'inherit';
        setLimitReached(false);
      } else {
        setLimitReached(false);
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
      setComposerRef(textareaRef);
    }
    if (!isTypingState) {
      setisTypingState(true);
      typing({
        chatId,
        alias, 
        secureKey, 
        userId, 
        nextPosition,
        message: newMessage,
        isStartTyping: true
      });
    }
  };

  const stopTyping = useCallback(() => {
    typing({
      chatId,
      alias, 
      secureKey, 
      userId, 
      nextPosition,
      message: debouncedInputValue,
      isStartTyping: false
    })
      .then(() => {
        setisTypingState(false);
      });
  }, [chatId, alias, secureKey, userId, nextPosition, debouncedInputValue]);

  useEffect(() => {
    if (newMessage === debouncedInputValue && isTypingState && newMessage !== '') {
      stopTyping()
    }
  }, [debouncedInputValue, newMessage, isTypingState, stopTyping]);

  return (
    <div className={`${classes.MessageComposer} ${multiLineStart ? classes.composerBaseline : ''}`}>
        <div className={`${classes.textContainer} ${limitReached ? classes.limitReached : ''}`}>
        {!chatEnded ? (
          <><Text
            value={newMessage}
            onChange={handleTypingStart}
            placeholder={t('composer.placeholder','Type your message here...')}
            onEnter={sendMessage}
            name='message'
            maxLength={250}
            disabled={!chatId ? true : false}
          /> {limitReached && <p className={classes.error}>{t('composer.limitReached','250-character limit reached')}</p> }</>) : null}
          
      </div>
      
      {!chatEnded && chatId && mode === 'agent' && fileuploadEnabled ? (
        <button translate="no" className={classes.AttachFile} onClick={showUploadModal}>
          <MuiPlaceholder
            isMuiFontLoaded={muiFontLoaded}
            element={<div>attach_file</div>}
            width={30}
            height={30}
            backgroundColor={'#eeeeee'} />
        </button>
      ) : null}
      {!chatEnded && chatId ? (
        <button className={classes.Send} onClick={sendMessage}>
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" />
            <path d="M16 24L17.41 25.41L23 19.83V32H25V19.83L30.58 25.42L32 24L24 16L16 24Z" fill="white"/>
          </svg>
        </button>
      ) : null}
    </div>
  );
};

export default MessageComposer;
