import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import classes from './MessageList.module.scss';
import Avatar from '../../components/Avatar/Avatar';
import Incoming from './MessageBubbles/Incoming/Incoming';
import IncomingAgent from './MessageBubbles/IncomingAgent/IncomingAgent';
import IncomingSystem from './MessageBubbles/IncomingSystem/IncomingSystem';
import Outgoing from './MessageBubbles/Outgoing/Outgoing';
import { useSelector } from 'react-redux';

const MessageList = (props) => {
  const { messages } = useSelector(state => state.messages);
  const { avatarUrl } = useSelector(state => state.general);
  const scrollToBottom = props.scrollToBottom;

  useEffect(() => {
    setTimeout(()=>{
    scrollToBottom(true);
  },600)
  }, [messages, scrollToBottom]);

  const renderMessageBubbles = () => {
    return messages.map((message, index, array) => {
      const avatarSize = 45;
      let isTop = true;
      let isBottom = true;
      let messageBubbleOuterClasses = [classes.messageBubbleOuter];
      let messageDateTime = !message.utcTime ? '' : new Date(message.utcTime);
      let messageTimestamp = messageDateTime !== '' ? messageDateTime.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      }) : '';

      if (message.isSystem) {
        isTop = false;
        isBottom = message.isSystemBottom;
      } else {
        // Determine message position and spacing
        if (index !== 0 && array[index - 1].incoming === message.incoming && !array[index - 1].isSystem) {
          isTop = false;
        }
        if (
          index + 1 < array.length &&
          array[index + 1].incoming === message.incoming && !array[index + 1].isSystem
        ) {
          isBottom = false;
        } else {
          isBottom = true;
        }
      }
      
      if (isBottom) {
        messageBubbleOuterClasses.push('mb-3');
      }
      
      return (
        <div className={messageBubbleOuterClasses.join(' ')} key={message.id}>
          {!message.isSystem ? (
            <div
              className={classes.avatarContainer}
              style={{ minWidth: avatarSize, height: isBottom ? avatarSize : 0, marginBottom: '30px', paddingTop: messageTimestamp === '' ? '5px' : '0'}}
            >
              {message.incoming && isBottom ? (
                <Avatar
                  isAgent={message.isAgent}
                  height={avatarSize}
                  width={avatarSize}
                  pictureUrl={avatarUrl}
                />
              ) : null}
            </div>
          ) : null }
          {message.incoming && !message.isAgent && !message.isSystem ? (
            <Incoming message={message} isTop={isTop} isBottom={isBottom} timeStamp={messageTimestamp} scrollToBottom={scrollToBottom} />
          ) : null}
          {message.incoming && message.isSystem ? (
            <IncomingSystem message={message} isTop={isTop} isBottom={isBottom} timeStamp={messageTimestamp} />
          ) : null}
          {message.incoming && message.isAgent ? (
            <IncomingAgent message={message} isTop={isTop} isBottom={isBottom} timeStamp={messageTimestamp} />
          ) : null}
          {!message.incoming ? (
            <Outgoing message={message} isTop={isTop} isBottom={isBottom} timeStamp={messageTimestamp}/>
          ) : null}
        </div>
      );
    });
  };

  return <div className={classes.MessageList}>
    {messages.length === 0 ? (
      <div className={classes.Ripple}>
        <div>
          <div></div><div></div>
          <span>
            <Trans i18nKey="container.loading">
              Loading
            </Trans>
          </span>
        </div>
      </div>
    ) : null}
    {renderMessageBubbles()}
  </div>;
};

export default MessageList;
