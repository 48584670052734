import React from "react";
import PropTypes from "prop-types";
import videoClassesModule from "./Video.module.scss";

function Video({videoPath}) {
  const handleImageOnClick = () => {
    window.open(videoPath)
  }

  const videoId = (videoPath) => {
    var url = new URL(videoPath);
    var pathSegments = url.pathname.split('/');
    var lastSegment = pathSegments[pathSegments.length - 1];
    return lastSegment ? lastSegment : null;
  }

  return (
    <div className={videoClassesModule.VideoContainer}>
      <div
        className={`${videoClassesModule.containerStyle} ${videoClassesModule.imageBorder}`}
      >
        <div className={videoClassesModule.imageStyle} onClick={handleImageOnClick}>
          <div className={videoClassesModule.thumbnail}>
            <img
              alt=""
              src={`https://play.vidyard.com/${videoId(videoPath)}.jpg`}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
            <div className={videoClassesModule.playIcon}>
              <div></div>
              <svg viewBox="0 0 32 32" fill="#fff" focusable="false" role="img" aria-label="Play Video"><title>Play Video</title><path id="play" data-testid="play" d="M6.484 4.094l20.75 11.225c0.226 0.121 0.41 0.427 0.41 0.683s-0.184 0.563-0.41 0.683l-20.75 11.222c-0.095 0.051-0.26 0.093-0.367 0.093-0.427 0-0.774-0.346-0.774-0.773v-22.451c0-0.428 0.347-0.774 0.774-0.774 0.108 0 0.272 0.042 0.367 0.093z"></path></svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Video.propTypes = {
  videoPath: PropTypes.string,
};

export default Video;
