
export const generateSurveyLink = (chatId, agentName) => {
    const surveyConfig = window.sutherland_variables.survey;
    const genesysConfig = window.sutherland_variables.genesys;
    const params = new URLSearchParams();
    const unixTime = Math.floor(Date.now() / 1000);

    params.append('platformName', surveyConfig.platformName);
    params.append('tenantName', surveyConfig.tenantName);
    params.append('surveyName', surveyConfig.surveyName);
    params.append('customerId', genesysConfig.emailAddress);
    params.append('interactionID', chatId);
    params.append('interactionTime', unixTime);
    params.append('agent', agentName);
    params.append('language', surveyConfig.language);
    params.append('clientName', surveyConfig.clientName);
    params.append('additionalData', JSON.stringify({
    }));
    
    const queryString = params.toString();
    return (`${surveyConfig.baseApiUrl}/create?${queryString}`);
}

