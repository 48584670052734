import { Provider } from 'react-redux';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import classes from './App.module.scss';
import RootContainer from './containers/RootContainer/RootContainer';
import { PersistGate } from 'redux-persist/integration/react';
import {store, persistor} from './store/configureStore';

function App() {
  const genesysDefaultLanguage = window.sutherland_variables.genesys.default_language;
  const genesysLanguages = window.sutherland_variables.genesys.languages;
  const langOrder = window.sutherland_variables.genesys.langOrder;
  let supportedLanguages = window.sutherland_variables.genesys.supported_languages;
  supportedLanguages = [...supportedLanguages,'es']

  let languageCode = genesysDefaultLanguage;
  let language = '';
  const path = window.location.pathname.toLowerCase();

  const queryParams = new URLSearchParams(window.location.search);
  const htmlLang = document.documentElement.lang.toLowerCase();
  
  let found = false;
  for (const method of langOrder) {
    if (method === "query" && queryParams.has('language')) {
      languageCode = queryParams.get('language');
      console.log('Language switch used:', method)
      found = true;
    } else if (method === "tag" && supportedLanguages.includes(htmlLang)) {
      languageCode = htmlLang;
      console.log('Language switch used:', method)
      found = true;
    } else if (method === "path") {
      for (const lang of supportedLanguages) {
        if (path.indexOf(`/${lang}`) !== -1) {
          languageCode = lang;
          found = true;
          break;
        }
      }
      console.log('Language switch used:', method)
    } else if (method === "browser") {
      languageCode = navigator.language || navigator.userLanguage;
      languageCode = languageCode === 'en-US' ? 'en' : languageCode;
      languageCode = languageCode.toLowerCase();
      console.log('Language switch used:', method)
      found = true;
    }

    if (found) {
      break;
    }
  }
  
  if(languageCode === 'es') {
    languageCode = 'espanol'
  }

  console.log('Language code loaded:', languageCode)

  language = genesysLanguages[languageCode];
  
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: false,
      lng: language,
      fallbackLng: languageCode,
      interpolation: {
        escapeValue: false,
      },
      resources: window.sutherland_variables.i18n
    });
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <div className={classes.App}>
           <RootContainer languageCode={languageCode} languageName= {language} />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;