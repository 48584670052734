import { 
  INITIATE_GENESYS_SESSION,
  REFRESH_GENESYS_SESSION,
  DISCONNECT_GENESYS_SESSION,
  RESET_GENESYS_SESSION,
  SET_GENESYS_FILE_CONFIG,
  SET_API_ERROR,
  GENESYS_RESET_STATE
} from '../constants/genesys';

const initialState = {  
  alias: "",
  chatEnded: false,
  chatId: "",
  messages: [],
  monitored: false,
  nextPosition: 0,
  secureKey: "",
  statusCode: 0,
  userId: "",
  hasApiError: false,
  fileConfig: false
};

const genesys = (state = initialState, action) => {
  switch (action.type) {
    case INITIATE_GENESYS_SESSION:
      return {
        ...state,
        ...action.data
      };
    case REFRESH_GENESYS_SESSION:
      return {
        ...state,
        ...action.data
      };
    case DISCONNECT_GENESYS_SESSION:
      return {
        ...state,
        chatEnded: true,
        messages: [],
        monitored: false,
        nextPosition: 1,
        statusCode: 0,
        fileConfig: false,
        hasApiError: false
      };
    case RESET_GENESYS_SESSION:
      return {  
        alias: "",
        chatEnded: false,
        chatId: "",
        messages: [],
        monitored: false,
        nextPosition: 0,
        secureKey: "",
        statusCode: 0,
        userId: "",
        fileConfig: false,
        hasApiError: false
      };
    case SET_GENESYS_FILE_CONFIG:
      return {
        ...state,
        fileConfig: action.config
      };
    case SET_API_ERROR:
      return {
        ...state,
        hasApiError: action.hasError
      };
    case GENESYS_RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default genesys;
