import { 
  GENERAL_RESET_STATE,
  SET_WIDGET_IS_OPEN,
  SET_DRAWER_IS_OPEN,
  SET_AVATAR_URL, 
  SET_AGENT_NAME, 
  SET_PRECHAT_ENABLED, 
  SET_PRECHAT_FORM_OPENED,
  SET_SESSION_ID,
  SET_SOCKET_ID,
  MUI_FONT_LOADED,
  SET_RESET_CHAT,
  SHOW_FEEDBACK,
  SET_SESSION_START_TIME,
  SET_LANGUAGECODE,
  SET_LANGUAGENAME
} from '../constants/general';

export const generalReset = () => {
  return (dispatch) => {
    dispatch({
      type: GENERAL_RESET_STATE
    });
  };
};

export const setSessionId = (sessionId) => {
  return (dispatch) => {
    dispatch({
      type: SET_SESSION_ID,
      sessionId: sessionId
    });
  };
};

export const setSocketId = (socketId) => {
  return (dispatch) => {
    dispatch({
      type: SET_SOCKET_ID,
      socketId: socketId
    });
  };
};

export const setAvatarUrl = (avatarUrl) => {
  return (dispatch) => {
    dispatch({
      type: SET_AVATAR_URL,
      avatarUrl: avatarUrl
    });
  };
};

export const setAgentName = (agentName) => {
  return (dispatch) => {
    dispatch({
      type: SET_AGENT_NAME,
      agentName: agentName
    });
  };
};

export const setWidgetIsOpen = (widgetIsOpen) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_WIDGET_IS_OPEN,
      widgetIsOpen: widgetIsOpen
    });
  };
};

export const setDrawerIsOpen = (isOpen) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_DRAWER_IS_OPEN,
      drawerIsOpen: isOpen
    });
  };
};

export const setPrechatEnabled = (prechatEnabled) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_PRECHAT_ENABLED,
      prechatEnabled: prechatEnabled
    });
  };
};

export const setPrechatFormOpened = (prechatFormOpened) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_PRECHAT_FORM_OPENED,
      prechatFormOpened: prechatFormOpened
    });
  };
};

export const setMuiFontLoaded = (isLoaded) => {
  return (dispatch, getState) => {
    dispatch({
      type: MUI_FONT_LOADED,
      muiFontLoaded: isLoaded
    });
  };
};

export const setResetChat = (isReset) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_RESET_CHAT,
      resetChat: isReset
    });
  };
};

export const setSessionStartTime = () => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_SESSION_START_TIME,
    });
  };
};

export const setShowFeedback = (isShown) => {
  return (dispatch, getState) => {
    dispatch({
      type: SHOW_FEEDBACK,
      show: isShown
    });
  };
};

export const setLanguageCode = (languageCode) => {
  return (dispatch) => {
    dispatch({
      type: SET_LANGUAGECODE,
      languageCode: languageCode
    });
  };
};

export const setLanguageName = (languageName) => {
  return (dispatch) => {
    dispatch({
      type: SET_LANGUAGENAME,
      languageName: languageName
    });
  };
};