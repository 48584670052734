import React, { useEffect, useRef }from 'react';
import PropTypes from 'prop-types';
import classes from './PopOver.module.scss';

function PopOver(props) {
  const popupDiv = useRef(null);

  useEffect(() => {
    if (popupDiv && popupDiv.current && popupDiv.current.style) {
      if (props.opened) {
        popupDiv.current.style.zIndex = "2";
      } else {
        setTimeout(() => {
          popupDiv.current.style.zIndex = "-1";
        }, 200)
      }
    }
    document.addEventListener('click', props.close);
    return () => {
      document.removeEventListener('click', props.close);
    };
  }, [props.close, props.opened]);

  return <div ref={popupDiv} className={props.opened ? classes.PopOverOpened : classes.PopOverClosed}>{props.children}</div>;
}

PopOver.propTypes = {
  opened: PropTypes.bool,
  close: PropTypes.func,
};

export default PopOver;
