import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import messages from './reducers/messages';
import general from './reducers/general';
import genesys from './reducers/genesys';
import prechat from './reducers/prechat';
import authentication from './reducers/authentication';
import { authenticationReset } from '../store/actions/authentication';
import { generalReset } from '../store/actions/general';
import { genesysReset } from '../store/actions/genesys';
import { messagesReset } from '../store/actions/messages';

const component = document.getElementsByTagName("sutherland-messenger")[0];
const botId = component.getAttribute('bot-id');
const configKey = `${window.location.hostname}_${botId}_root`

const persistConfig = {
  key: configKey,
  storage
};

const rootReducer = combineReducers({
  messages: messages,
  general: general,
  prechat: prechat,
  authentication: authentication,
  genesys: genesys
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, applyMiddleware(ReduxThunk));
store.subscribe(() => {
  // Check persist timeout
  const state = store.getState()
  const currentDate = new Date(); // Get the current date and time
  const sessionStart = new Date(state.general.sessionStartTime);
  const timeDifference = currentDate.getTime() - sessionStart.getTime(); // Calculate the difference in milliseconds
  const secondsElapsed = timeDifference / 1000; // Convert milliseconds to seconds
  const sessionExpiry = window.sutherland_variables ? window.sutherland_variables.sessionExpiry : null;
  const sessionExpirySeconds = !sessionExpiry ? 30 * 60 * 3 : sessionExpiry; // Defaults to 3 hours
  
  if (secondsElapsed > sessionExpirySeconds) {
    console.log('\u25B6 Chat session expired, restarting...');
    store.dispatch(generalReset());
    setTimeout(() => {
      store.dispatch(authenticationReset());
      store.dispatch(genesysReset());
      store.dispatch(messagesReset());
    }, 250)
  }
});

export const persistor = persistStore(store);
