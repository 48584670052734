import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { downloadFile } from '../../../../api/Connect/Connect';
import outgoingClassesModule from './Outgoing.module.scss';
import messageBubbleClassesModule from '../MessageBubble.module.scss';

function Outgoing(props) {
  const { chatId, alias, secureKey, userId, chatEnded } = useSelector(state => state.genesys);
  const [ isDownloading, setIsDownloading ] = useState(false);
  const outgoingClasses = [outgoingClassesModule.Outgoing, messageBubbleClassesModule.MessageBubble];
  const configOutgoing = window.sutherland_variables.ui.outgoing_messages;
  const bubbleStyles = configOutgoing ? {
    color: configOutgoing.text_color,
    backgroundColor: configOutgoing.bubble_color,
  } : {}

  const handleDownloadFile = () => {
    if (!chatEnded && !isDownloading) {
      setIsDownloading(true);
      downloadFile({
        chatId, 
        alias,
        secureKey, 
        userId
      }, props.message.fileData["file-id"])
      .then((fileData) => {
        
          const fileBlob = new Blob([fileData]);
    
          const fileURL = URL.createObjectURL(fileBlob);
    
          const downloadLink = document.createElement('a');
          downloadLink.href = fileURL;
          downloadLink.target = '_blank';
          downloadLink.download = props.message.fileData["file-name"]; 
    
          document.body.appendChild(downloadLink);
          downloadLink.click();
    
          URL.revokeObjectURL(fileURL);
          document.body.removeChild(downloadLink);
          setIsDownloading(false);
        })
        .catch((error) => {
          console.error('Error while downloading file:', error);
        });
    }
  }

  if (props.isTop) {
    outgoingClasses.push(outgoingClassesModule.top);
  }
  if (props.isBottom) {
    outgoingClasses.push(outgoingClassesModule.bottom);
  }

  if (props.message.isFileMessage) {
    const fileData = props.message.fileData;
    const fileSize = parseInt(fileData["file-size"]);
    return <div className={outgoingClassesModule.OutgoingContainer}>
      <div className={outgoingClasses.join(' ')} style={bubbleStyles}>
        You sent: <br/>
        <strong>{fileData["file-name"]}</strong> ({fileSize} Bytes)
        {!chatEnded &&
          <button 
            className={!isDownloading ? outgoingClassesModule.FileDownloadButton : outgoingClassesModule.FileDownloadButtonDisabled}
            onClick={handleDownloadFile}
          >{!isDownloading ? 'Download' : 'Downloading ...'}</button>
        }
      </div>
      <div className={messageBubbleClassesModule.outgoingTimeStamp}>
        {props.timeStamp}
      </div>
    </div>;  
  }

  return <div className={outgoingClassesModule.OutgoingContainer}>
    <div className={outgoingClasses.join(' ')} style={bubbleStyles}>
      {props.message.text}
    </div>
    <div className={messageBubbleClassesModule.outgoingTimeStamp}>
      {props.timeStamp}
    </div>
  </div>;
}

Outgoing.propTypes = {
  message: PropTypes.object,
  isTop: PropTypes.bool,
  isBottom: PropTypes.bool,
  timeStamp: PropTypes.string
};

export default Outgoing;
