import { SAVE_PRECHAT, RESET_PRECHAT } from '../constants/prechat';

export const savePrechat = (prechatData) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_PRECHAT,
      firstName: prechatData.firstName,
      lastName: prechatData.lastName,
      email: prechatData.email,
      reason: prechatData.reason,
    });
  };
};

export const resetPrechat = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_PRECHAT
    });
  };
};