import React from 'react';
import PropTypes from 'prop-types';
import classes from './Select.module.scss';

const Select = (props) => {
  const {name, onChange, value, options, placeholder, showError, errorMessage} = props;

  return (
    <div className={showError ? classes.SelectError : classes.Select}>
        <select 
            value={value} 
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            className='focus:outline-none focus:shadow-outline'>
            {options.map((option) => (
                <option key={`${name}-${option.value}`} text={option.label} value={option.value}>{option.label}</option>
            ))}
        </select>
      {showError ? (
        <div className={classes.ErrorMessage}>{errorMessage}</div>
      ) : null}
    </div>
  );
};

Select.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    showError: PropTypes.bool,
    errorMessage: PropTypes.string,
};

export default Select;
