import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Video from '../Video/Video';
import incomingClassesModule from './Incoming.module.scss';
import messageBubbleClassesModule from '../MessageBubble.module.scss';

function Incoming(props) {
  const incomingClasses = [incomingClassesModule.Incoming, messageBubbleClassesModule.MessageBubble];
  const configIncoming = window.sutherland_variables.ui.incoming_messages;
  const bubbleStyles = configIncoming ? {
    color: configIncoming.text_color,
    backgroundColor: configIncoming.bubble_color,
  } : {}

  if (props.isTop) {
    incomingClasses.push(incomingClassesModule.top);
  }
  if (props.isBottom) {
    incomingClasses.push(incomingClassesModule.bottom);
  }

  const scrollToBottom = props.scrollToBottom;
  useEffect(() => {
    scrollToBottom(true);
  }, [scrollToBottom]);

  const findVideo = (inputString, substringToSearch) => {
    if (inputString.includes(substringToSearch)) {
        var regex = /https?:\/\/[^\s)]+(?=\])?/;
        const match = regex.exec(inputString);
        return match ? match[0] : null;;
    } else {
        return null
    }
  }
  const videoPath = findVideo(props.message.text, "share.vidyard.com");

  return (
    <div className={messageBubbleClassesModule.incomingOuter}>
      {videoPath ? 
        <div>
          <Video videoPath={videoPath} />
        </div>
      :
        <>
          <div className={incomingClasses.join(' ')} style={bubbleStyles}>
            <ReactMarkdown 
              children={props.message.text} 
              remarkPlugins={[remarkGfm]} 
              linkTarget="_blank"
            />
          </div>
          {props.isBottom ? (
            <div className={messageBubbleClassesModule.timeStamp}>
              {props.timeStamp}
            </div>
          ) : null}
        </>
      }
    </div>
  );
}

Incoming.propTypes = {
  message: PropTypes.object,
  isTop: PropTypes.bool,
  isBottom: PropTypes.bool,
  timeStamp: PropTypes.string
};

export default Incoming;
