import React, { useMemo, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import FontFaceObserver from 'fontfaceobserver';
import PrechatForm from '../PrechatForm/PrechatForm';
import MainContainer from '../MainContainer/MainContainer';
import ChatLauncher from '../../components/ChatLauncher/ChatLauncher';
import { setMuiFontLoaded, setLanguageCode, setLanguageName } from '../../store/actions/general';

function RootContainer({languageCode, languageName}) {
  const { prechatEnabled, prechatFormOpened, widgetIsOpen } = useSelector(state => state.general);
  const { chatEnded } = useSelector(state => state.genesys);
  const { messages } = useSelector(state => state.messages);
  const genesysLanguages = window.sutherland_variables.genesys.languages;
  const languageRef = useRef('');

  const dispatch = useDispatch();
  const Container = () => {
    if (prechatEnabled && prechatFormOpened) {
      return (
        <PrechatForm />
      )
    }

    return (
      <MainContainer />
    )
  }

  useEffect(() => {
    dispatch(setLanguageCode(languageCode));
    dispatch(setLanguageName(languageName));
  },[dispatch, languageCode, languageName])

  useEffect(() => {
    const font = new FontFaceObserver('Material Icons');

    dispatch(setMuiFontLoaded(false))
    font.load(null, 10000)
      .then(() => {
        dispatch(setMuiFontLoaded(true))
      })
      .catch((error) => {
        dispatch(setMuiFontLoaded(false))
      });
  }, [dispatch]);

  const changeLanguage = useCallback(() => {
    const htmlElement = document.documentElement;
    const newLang = htmlElement.lang.toLowerCase();
    let supportedLanguages = window.sutherland_variables.genesys.supported_languages;
    supportedLanguages = [...supportedLanguages,'es']

    if (supportedLanguages.includes(newLang)) {
      let languageCode = 'en';
      if (newLang === 'es') {
        languageCode = 'espanol';
      }

      languageRef.current = genesysLanguages[languageCode]; // Update ref value

      i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
          debug: false,
          lng: languageRef.current,
          fallbackLng: languageCode,
          interpolation: {
            escapeValue: false,
          },
          resources: window.sutherland_variables.i18n,
      });
      dispatch(setLanguageCode(languageCode));
      dispatch(setLanguageName(languageRef.current));
    }
  }, [genesysLanguages, dispatch]);

  useEffect(() => {
    if(!widgetIsOpen && !chatEnded && messages.length === 0) {
      const htmlElement = document.documentElement;
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'lang') {
            changeLanguage();
          }
        });
      });
      observer.observe(htmlElement, { attributes: true });
      return () => observer.disconnect();
    }
  }, [genesysLanguages, widgetIsOpen, changeLanguage, chatEnded, messages]);

  useEffect(() => {
    if(!widgetIsOpen && !chatEnded && messages.length === 0) {
      changeLanguage();
    }
  }, [widgetIsOpen, changeLanguage, chatEnded, messages]);

  return useMemo(() => {
    return (
      <div>
        {widgetIsOpen ? (
          <Container />
        ) : null}
        <ChatLauncher />
      </div>
    );
  }, [widgetIsOpen])
}

export default RootContainer;
