import { SAVE_PRECHAT, RESET_PRECHAT } from '../constants/prechat';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  reason: ''
};

const prechat = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PRECHAT:
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email,
        reason: action.reason,
      };

    case RESET_PRECHAT:
      return initialState;
    default:
      return state;
  }
};

export default prechat;
