import { 
  ADD_MESSAGE,
  SET_MESSAGES,
  SET_QUICK_RESPONSES,
  SET_MESSAGES_LOADED,
  SET_MODE,
  SET_AGENT_STATUS,
  SET_IS_TYPING,
  SHOW_UPLOAD,
  MESSAGES_RESET_STATE
} from '../constants/messages';

const initialState = {
  messages: [],
  quickResponses: [],
  messagesLoaded: false,
  mode: 'bot', // Possible values: bot, user, agent
  agentStatus: '', // Possible values: available, unavailable, session-ended, is-typing, stop-typing
  typing: false,
  showUpload: false
};

const messages = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGES:
      return {
        ...state,
        messages: action.messages
      };

    case ADD_MESSAGE:
      let messageArray = JSON.parse(JSON.stringify(state.messages));
      messageArray.push(action.message);
      
      // Remove message duplicates
      messageArray = messageArray.filter((obj, index, self) => {
        if (obj.incoming) {
          return index === self.findIndex((o) => (o.index === obj.index && o.text === obj.text));
        } 
        return true
      });

      return {
        ...state,
        messages: messageArray
      };
    
    case SET_QUICK_RESPONSES:
      return {
        ...state,
        quickResponses: action.quickResponses
      };
      
    case SET_MESSAGES_LOADED:
      return {
        ...state,
        messagesLoaded: action.messagesLoaded
      };
    
    case SET_MODE:
      return {
        ...state,
        mode: action.mode
      };
    
    case SET_AGENT_STATUS:
      return {
        ...state,
        agentStatus: action.agentStatus
      };
    
    case SET_IS_TYPING:
      return {
        ...state,
        typing: action.typing
      };
    
    case SHOW_UPLOAD:
      return {
        ...state,
        showUpload: action.show
      };
    case MESSAGES_RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default messages;
