import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classes from './PrechatForm.module.scss';
import Header from '../../components/Header/Header';
import Text from '../../components/controls/Text/Text';
import Select from '../../components/controls/Select/Select';
import CheckBox from '../../components/controls/CheckBox/CheckBox';
import { setWidgetIsOpen, setPrechatFormOpened } from '../../store/actions/general';
import { savePrechat } from '../../store/actions/prechat';

const reasonsData = [
  {label: "Please Select...", value: ""},
  {label: "Product Quality Concerns", value: "quality_option"},
  {label: "Feedback", value: "feedback_option"},
  {label: "Business Opportunities", value: "business_option"},
  {label: "Promotions", value: "promotions_option"},
  {label: "Coupons and Samples", value: "coupon_option"},
  {label: "Nutritional Info", value: "ingredient_option"},
  {label: "Where to Buy", value: "product_option"},
  {label: "Follow up on an open case", value: "open_case"}
];

const PrechatForm = () => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [reason, setReason] = useState('');
  const [reasonError, setReasonError] = useState(false);
  const [isAbove18, setIsAbove18] = useState(false);

  const closeWidget = () => {
    dispatch(setWidgetIsOpen(false));
  };

  const submitForm = () => {
    if (reason === '') {
      setReasonError(true);
    } else {
      setReasonError(false);
      dispatch(setPrechatFormOpened(false))
      dispatch(savePrechat({
        firstName, 
        lastName,
        email,
        reason
      }))
    };
  };

  return (
    <div className={classes.PrechatForm}>
      <Header />

      <div
        className={classes.content}
        style={{ overflowY: 'auto' }}
      >
        <div className='intro text-lg'>Hey there, before we start, please tell me more about yourself and your request.</div>
        <div className='intro text-sm'>Click on <b>Start Chat</b> to initiate our conversation or <b>Cancel</b> to close the chat session.</div>
        <form>
          <div className='mb-4'>
            <label>First Name</label>
            <Text
              value={firstName}
              onChange={setFirstName}
              placeholder={`Optional`}
              onEnter={submitForm}
              name='message'
              maxLength={120}
              rows={1}
            />
          </div>
          <div className='mb-4'>
            <label>Last Name</label>
            <Text
              value={lastName}
              onChange={setLastName}
              placeholder={`Optional`}
              onEnter={submitForm}
              name='message'
              maxLength={120}
              rows={1}
            />
          </div>
          <div className='mb-4'>
            <label>Email Address</label>
            <Text
              value={email}
              onChange={setEmail}
              placeholder={`Optional`}
              onEnter={submitForm}
              name='message'
              maxLength={120}
              rows={1}
            />
          </div>
          <div className='mb-6'>
            <label>Contact Reason*</label>
            <Select
              name="prechat-reason"
              value={reason}
              onChange={setReason}
              placeholder='Required'
              options={reasonsData}
              showError={reasonError}
              errorMessage="Please select your reason for contacting us."
            />
          </div>
          <div className='mb-6'>
            <CheckBox 
              label="Are you above the age of 16?"
              checked={isAbove18}
              onChange={setIsAbove18}
              required={true}
            />
          </div>

          <div className='flex items-center justify-between'>
            <button
              onClick={closeWidget}
              className='inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800' href='#'>
              Cancel
            </button>
            <button 
              onClick={submitForm}
              className="focus:outline-none focus:shadow-outline"
              type='button'
              disabled={isAbove18 ? false : true}>
              Start Chat
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PrechatForm;
