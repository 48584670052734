import React from 'react';
import { Trans } from 'react-i18next';
import classes from './EndChatModal.module.scss';
import Modal from '../Modal/Modal';
import { disconnect } from '../../api/Connect/Connect';
import { setQuickResponses } from '../../store/actions/messages';
import { setResetChat } from '../../store/actions/general';
import { disconnectGenesysSession } from '../../store/actions/genesys';
import { useDispatch, useSelector } from 'react-redux';

const EndChatModal = ({isOpen, closeHandler}) => {
    const { chatId, alias, secureKey, userId, chatEnded } = useSelector(state => state.genesys);

    const dispatch = useDispatch();

    const endChat = () => {
        dispatch(setQuickResponses([]));
        dispatch(setResetChat(false))
        dispatch(disconnectGenesysSession());
        window.sutherland_variables.chat_ended = true;
        disconnect({
            chatId, 
            alias, 
            secureKey,
            userId,
            chatEnded
        });
    }

    return (
        <Modal isOpen={isOpen} onClose={closeHandler} contentWidth={'60%'}>
            <div className={classes.EndChatModalContainer}>
                <h2>
                    <Trans i18nKey="buttons.endChat">
                      End chat
                    </Trans>
                </h2>
                <div className={classes.EndChatModalContent}>
                    <div className={classes.Label}>
                        <Trans i18nKey="endChat.title">
                          Are you sure you want to end this chat session?
                        </Trans>
                    </div>
                    <div className={classes.Buttons}>
                        <button className={classes.ButtonEnd} onClick={endChat}>
                            <Trans i18nKey="buttons.endChat">
                                End chat
                            </Trans>
                        </button>
                        <button className={classes.ButtonCancel} onClick={closeHandler}>
                            <Trans i18nKey="buttons.cancel">
                               Cancel
                            </Trans>
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default EndChatModal;
