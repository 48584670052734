import React from 'react';
import { Trans } from 'react-i18next';
import classes from './FeedbackModal.module.scss';
import Modal from '../Modal/Modal';
import { generateSurveyLink } from '../../api/Survey/Survey';
import { useSelector } from 'react-redux';

const FeedbackModal = ({isOpen, closeHandler}) => {
    const { chatId } = useSelector(state => state.genesys);
    const { agentName } = useSelector(state => state.general);
    
    const sendFeedback = () => {
        const newTab = window.open(generateSurveyLink(chatId, agentName), '_blank');
        closeHandler();
        newTab.focus();
    }

    return (
        <Modal isOpen={isOpen} onClose={closeHandler} contentWidth={'60%'}>
            <div className={classes.FeedbackModalContainer}>
                <h2>
                    <Trans i18nKey="feedback.title">
                      We value your feedback
                    </Trans>
                </h2>
                <div className={classes.FeedbackModalContent}>
                    <div className={classes.Label}>
                        <Trans i18nKey="feedback.content">
                          Thank you for contacting us, we hope we answered your questions today.
                        </Trans>

                    </div>
                    <div className={classes.Buttons}>
                        <button className={classes.ButtonEnd} onClick={sendFeedback}>
                            <Trans i18nKey="buttons.sendFeedback">
                              Send feedback
                            </Trans>
                        </button>
                        <button className={classes.ButtonCancel} onClick={closeHandler}>
                            <Trans i18nKey="buttons.skip">
                              Skip
                            </Trans>
                        </button> 
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default FeedbackModal;
